// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-background-jsx": () => import("./../../../src/templates/background.jsx" /* webpackChunkName: "component---src-templates-background-jsx" */),
  "component---src-templates-background-overview-jsx": () => import("./../../../src/templates/backgroundOverview.jsx" /* webpackChunkName: "component---src-templates-background-overview-jsx" */),
  "component---src-templates-episode-jsx": () => import("./../../../src/templates/episode.jsx" /* webpackChunkName: "component---src-templates-episode-jsx" */),
  "component---src-templates-navigation-jsx": () => import("./../../../src/templates/navigation.jsx" /* webpackChunkName: "component---src-templates-navigation-jsx" */),
  "component---src-templates-protagonist-jsx": () => import("./../../../src/templates/protagonist.jsx" /* webpackChunkName: "component---src-templates-protagonist-jsx" */),
  "component---src-templates-protagonists-overview-jsx": () => import("./../../../src/templates/protagonistsOverview.jsx" /* webpackChunkName: "component---src-templates-protagonists-overview-jsx" */)
}

